import { Category } from "../../types";
import { buildCategoryHierarchy } from "../../util/categoryUtils";
import { getAllCategoriesUrl } from "../endpoints";

export async function getCategories(): Promise<Category[]> {
  try {
    const response = await fetch(getAllCategoriesUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }
    const data = await response.json();
    if (!Array.isArray(data.data)) {
      throw new Error("Invalid response structure");
    }
    // Process the raw data into a hierarchy.
    return buildCategoryHierarchy(data.data);
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
}
