// user endpoints
const getUserByUUID: string = process.env.REACT_APP_GET_USER_BY_UUID || "";
const getUserBySSOid: string = process.env.REACT_APP_GET_USER_BY_SSOID || "";
const newUserMutationUrl: string = process.env.REACT_APP_POST_NEW_USER_API_URL || "";
const updateUserMutationUrl: string = process.env.REACT_APP_PUT_UPDATE_USER_API_URL || "";

// category endpoints
const getAllCategoriesUrl: string = process.env.REACT_APP_CATEGORY_API_URL || "";

// email campaign endpoints
const getAllCampaignsUrl: string = process.env.REACT_APP_CAMPAIGN_API_URL || "";

// program endpoints
const programSearchUrl: string = process.env.REACT_APP_PROGRAM_SEARCH_API_URL || "";
const programGetUrl: string = process.env.REACT_APP_PROGRAM_GET_API_URL || "";

export {
  getAllCategoriesUrl,
  getAllCampaignsUrl,
  getUserByUUID,
  getUserBySSOid,
  programSearchUrl,
  programGetUrl,
  newUserMutationUrl,
  updateUserMutationUrl,
};
