import { useAuth0 } from "@auth0/auth0-react";
import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Auth0LoginBtn from "../components/buttons/Auth0LoginBtn";
import AnimatedLogo from "../components/AnimatedLogo";
import ActionButton from "../components/buttons/ActionButton";

const Welcome: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Container
      sx={{
        mt: "15vh",
      }}
    >
      <Stack gap={2} alignItems="center">
        <Typography variant="h1" textAlign={"center"} lineHeight={1.5} mb={3}>
          Everything Happening in <br /> Denver. Right here.
        </Typography>
        <ActionButton text="Sign up" onClick={() => loginWithRedirect()} />
        <Typography variant="body2" textAlign={"center"} lineHeight={1.5}>
          Already have an account?
          <Auth0LoginBtn> Login</Auth0LoginBtn>
        </Typography>
        <AnimatedLogo size="100px" repeat={1} clickable={true} />
      </Stack>
    </Container>
  );
};

export default Welcome;
