import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  children: React.ReactNode;
};

/**
 * LoginButton component
 * alternative to using ActionButton -> no styling
 * @param children - React node to display
 * @returns LoginButton component
 */
const LoginButton = ({ children }: Props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <a
      style={{ cursor: "pointer" }}
      onClick={() => loginWithRedirect()}
    >
      {children}
    </a>
  );
};

export default LoginButton;
