import { Coordinate } from "../types";
import { debugLog } from "./logger";

interface CacheEntry {
  coords: Coordinate;
  timestamp: number;
  type: "user" | "zip";
}

export class LocationService {
  private static cacheMap: Map<string, CacheEntry> = new Map();
  private static USER_LOCATION_TTL = 2 * 60 * 1000; // 2 minutes in milliseconds

  // Function to fetch user location via geolocation with caching
  public static async getCurrentPosition(
    ask = false,
  ): Promise<Coordinate | null> {
    const cacheKey = "user_location";

    // Check the cache for recent user location
    const cachedEntry = this.cacheMap.get(cacheKey);
    if (cachedEntry && this.isCacheValid(cachedEntry)) {
      debugLog("Using cached user location");
      return cachedEntry.coords;
    }

    if (ask) {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });

      if (permissionStatus.state === "denied") {
        alert(
          "You have denied location access. Please enable it in your browser settings.",
        );
        return null;
      }
    }

    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject("Geolocation is not supported by your browser.");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords: Coordinate = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.cacheMap.set(cacheKey, {
            coords,
            timestamp: Date.now(),
            type: "user",
          });
          resolve(coords);
        },
        (error) => {
          console.warn("Error getting location:", error);
          reject("Unable to retrieve location.");
        },
      );
    });
  }

  // Function to fetch coordinates based on ZIP code with indefinite caching
  public static async getCoordsFromZip(
    zipCode: string,
  ): Promise<Coordinate | null> {
    const cacheKey = `zip_${zipCode}`;

    // Return cached coordinates if they exist
    const cachedEntry = this.cacheMap.get(cacheKey);
    if (cachedEntry) {
      debugLog(`Using cached coordinates for ZIP code: ${zipCode}`);
      return cachedEntry.coords;
    }

    try {
      const response = await fetch(`/api/geocode/findByZip/${zipCode}`);
      const data = await response.json();

      if (data.success && data.data) {
        const coords: Coordinate = {
          lat: data.data.latitude,
          lng: data.data.longitude,
        };
        this.cacheMap.set(cacheKey, {
          coords,
          timestamp: Date.now(),
          type: "zip",
        });
        return coords;
      } else {
        console.error("Failed to fetch coordinates for ZIP code");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data from ZIP code API:", error);
      return null;
    }
  }

  // Optionally reset specific cached coordinates
  public static resetCoords(type?: "user" | "zip"): void {
    if (type) {
      // Remove specific type of cached data
      for (const [key, entry] of this.cacheMap) {
        if (entry.type === type) {
          this.cacheMap.delete(key);
        }
      }
    } else {
      // Clear all cached data
      this.cacheMap.clear();
    }
  }

  public static async getZipFromCoords(
    coords: Coordinate,
  ): Promise<string | null> {
    try {
      const response = await fetch(`/api/geocode/findZipByLatLong`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(coords),
      });
      const responseBody = await response.json();
      if (responseBody.success && responseBody.data) {
        return responseBody.data.zipCode;
      }
    } catch (err) {
      debugLog("Error fetching ZIP code from coords:", err);
    }
    return null;
  }

  public static isValidZipCode(input: string): boolean {
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    return zipCodePattern.test(input);
  }

  // Helper to check if a cache entry is still valid
  private static isCacheValid(entry: CacheEntry): boolean {
    if (entry.type === "zip") {
      return true; // ZIP code coordinates are cached indefinitely
    }
    // User location coordinates expire after the TTL
    return Date.now() - entry.timestamp < this.USER_LOCATION_TTL;
  }
}
