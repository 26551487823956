import { Category, CategoryWithCount, Event, ProgramCategory, UserSelectedCategory } from "../types";

/**
 * Converts a flat list of categories into a hierarchy.
 * @param categories - A flat list of categories.
 * @returns A list of top-level categories with their subcategories.
 */
export function buildCategoryHierarchy(categories: Category[]): Category[] {
  const categoryMap: { [key: string]: Category } = {};
  // Create a new object for each category with an empty subcategories array.
  categories.forEach((category) => {
    categoryMap[category.categoryCode] = { ...category, subcategories: [] };
  });
  // Link subcategories to their parent.
  categories.forEach((category) => {
    if (category.parent && category.parent.categoryCode) {
      const parentCode = category.parent.categoryCode;
      if (categoryMap[parentCode]) {
        categoryMap[parentCode].subcategories?.push(
          categoryMap[category.categoryCode]
        );
      }
    }
  });
  // Return only top-level categories.
  return Object.values(categoryMap).filter((cat) => !cat.parent);
}

/**
 * Given a hierarchical list of categories and a list of events,
 * computes an event count for each category (including counts from subcategories' events).
 * @param categories - A list of categories.
 * @param events - A list of events.
 * @returns An object containing the categories with their event counts and the top-level categories.
 */
export function calculateCategoryEventCounts(
  categories: Category[],
  events: Event[]
): {
  categoriesWithCount: CategoryWithCount[];
  topLevelCategories: CategoryWithCount[];
} {
  const categoryCountMap: Record<string, CategoryWithCount> = {};

  // Recursively traverse the category tree and initialize each category.
  const addCategoryWithSubcategories = (cat: Category): void => {
    if (categoryCountMap[cat.categoryCode]) return;
    categoryCountMap[cat.categoryCode] = {
      ...cat,
      eventCount: 0,
      subcategories: [],
    } as CategoryWithCount;
    if (cat.subcategories && cat.subcategories.length > 0) {
      cat.subcategories.forEach((sub) => {
        addCategoryWithSubcategories(sub);
        categoryCountMap[cat.categoryCode].subcategories.push(
          categoryCountMap[sub.categoryCode]
        );
      });
    }
  };

  categories.forEach(addCategoryWithSubcategories);

  // Increment counts for categories referenced in events.
  events.forEach((event) => {
    const countedCategories = new Set<string>();
    event.categories.forEach(({ category }: ProgramCategory) => {
      let current: Category | null = category;
      while (current) {
        if (!countedCategories.has(current.categoryCode)) {
          if (categoryCountMap[current.categoryCode]) {
            categoryCountMap[current.categoryCode].eventCount += 1;
          }
          countedCategories.add(current.categoryCode);
        }
        current = current.parent || null;
      }
    });
  });

  const categoriesWithCount = Object.values(categoryCountMap);
  const topLevelCategories = categoriesWithCount.filter((cat) => !cat.parent);
  return { categoriesWithCount, topLevelCategories };
}

/**
 * Creates a flattened lookup map from a category tree.
 * Useful for recursively retrieving subcategory codes.
 * @param categories - A list of categories.
 * @returns A lookup map of categories.
 */
export function flattenCategoryTreeToMap(
  categories: Category[]
): Map<string, Category> {
  const map = new Map<string, Category>();
  const flatten = (cat: Category) => {
    map.set(cat.categoryCode, cat);
    if (cat.subcategories && cat.subcategories.length > 0) {
      cat.subcategories.forEach(flatten);
    }
  };
  categories.forEach(flatten);
  return map;
}

/**
 * filters just categories with no parent
 * @param category user selected category object
 * @returns boolean
 */
export const categoriesWithNoParentFilter = (category: UserSelectedCategory) => {
  return category.category.parent === null;
};

/**
 * maps existing category object to just a string
 * @param category user selected category object
 * @returns string of category code for use in form
 */
export const getCategoryCode = (category: UserSelectedCategory) => category.category.categoryCode;
