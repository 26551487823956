import { Button, ButtonProps } from "@mui/material";

type Props = ButtonProps & {
  text: string;
};

const ActionButton = ({ text, sx, ...props }: Props) => {
  return (
    <Button
      color="primary"
      variant="contained"
      sx={{
        backgroundColor: "#c24444",
        color: "white",
        width: "120px",
        borderRadius: "64px",
        "&:hover": {
          color: "#c24444",
          backgroundColor: "#EAEAEA",
        },
        ...sx,
      }}
      {...props}
    >
      {text}
    </Button>
  );
};

export default ActionButton;
