import { Event, SearchParameters } from "../../types";
import { programGetUrl, programSearchUrl } from "../endpoints";

type CacheEntry = {
  timestamp: number;
  data: any;
};

const CACHE_DURATION = 5 * 60 * 1000; // Cache duration in milliseconds (e.g., 5 minutes)
const MOST_RECENT_SEARCH_KEY = "mostRecentSearch";

// Get all cache keys that store search results
function getAllSearchCacheKeys(): string[] {
  return Object.keys(localStorage).filter((key) =>
    key.startsWith("searchCache_")
  );
}

function sortObject(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObject);
  }

  const sortedKeys = Object.keys(obj).sort();
  const result: any = {};
  for (const key of sortedKeys) {
    result[key] = sortObject(obj[key]);
  }
  return result;
}

function serializeParams(params: SearchParameters): string {
  const sortedParams = sortObject(params);
  return JSON.stringify(sortedParams);
}

function getCacheKey(params: SearchParameters): string {
  return `searchCache_${serializeParams(params)}`;
}

// Method to check if there's a valid cached entry for the given searchParams
export function isCached(searchParams: SearchParameters): boolean {
  const key = getCacheKey(searchParams);
  const now = Date.now();
  cleanExpiredCache();

  const cachedEntryString = localStorage.getItem(key);
  if (cachedEntryString) {
    try {
      const cachedEntry: CacheEntry = JSON.parse(cachedEntryString);
      // Check if cache is still valid
      if (now - cachedEntry.timestamp < CACHE_DURATION) {
        return true;
      }
    } catch (error) {
      console.error("Error parsing cache entry:", error);
    }
  }

  return false;
}

// Method to retrieve the most recent search parameters
export function getMostRecentSearchParams(): SearchParameters | null {
  const mostRecentSearchString = localStorage.getItem(MOST_RECENT_SEARCH_KEY);
  if (mostRecentSearchString) {
    try {
      return JSON.parse(mostRecentSearchString);
    } catch (error) {
      console.warn("Error parsing most recent search parameters:", error);
      return null;
    }
  }
  return null;
}

// New helper to clean expired cache entries
function cleanExpiredCache(): void {
  const now = Date.now();
  getAllSearchCacheKeys().forEach((key) => {
    try {
      const cachedEntry: CacheEntry = JSON.parse(
        localStorage.getItem(key) || "{}"
      );
      if (now - cachedEntry.timestamp >= CACHE_DURATION) {
        localStorage.removeItem(key);
      }
    } catch {
      localStorage.removeItem(key); // Remove corrupted cache entries
    }
  });
}

// Helper to remove oldest cache entries until there is enough space
function removeOldestCacheEntries(): void {
  const cacheEntries = getAllSearchCacheKeys()
    .map((key) => {
      const cachedEntry = JSON.parse(localStorage.getItem(key) || "{}");
      return { key, timestamp: cachedEntry.timestamp || 0 };
    })
    .sort((a, b) => a.timestamp - b.timestamp); // Sort by oldest timestamp

  while (cacheEntries.length > 0) {
    const oldestEntry = cacheEntries.shift();
    if (oldestEntry) {
      localStorage.removeItem(oldestEntry.key);
      if (localStorage.getItem(oldestEntry.key) === null) {
        break; // If successfully removed, stop
      }
    }
  }
}

export async function searchPrograms(searchParams: SearchParameters) {
  const key = getCacheKey(searchParams);
  const now = Date.now();

  // Clean up old cache entries before adding new ones
  cleanExpiredCache();

  // Retrieve cache from localStorage
  const cachedEntryString = localStorage.getItem(key);

  if (cachedEntryString) {
    try {
      const cachedEntry: CacheEntry = JSON.parse(cachedEntryString);

      if (now - cachedEntry.timestamp < CACHE_DURATION) {
        return cachedEntry.data; // Return cached data
      } else {
        // Remove expired cache entry
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.error("Error parsing cache entry:", error);
      localStorage.removeItem(key); // If parsing fails, remove the invalid cache entry
    }
  }

  // If not cached or expired, make the API call
  try {
    const response = await fetch(programSearchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchParams),
    });

    if (!response.ok) {
      throw new Error("Failed to search programs");
    }

    const data = await response.json();

    // Try to store the result in the cache
    try {
      const cacheEntry: CacheEntry = {
        timestamp: now,
        data,
      };

      try {
        localStorage.setItem(key, JSON.stringify(cacheEntry));

        // Also store the most recent search parameters
        localStorage.setItem(
          MOST_RECENT_SEARCH_KEY,
          JSON.stringify(searchParams)
        );
      } catch (cacheError) {
        // Handle QuotaExceededError by removing old entries and retrying
        if (
          cacheError instanceof DOMException &&
          cacheError.name === "QuotaExceededError"
        ) {
          console.warn("Quota exceeded, clearing oldest cache items...");
          removeOldestCacheEntries();

          // Retry storing the data after removing old cache entries
          localStorage.setItem(key, JSON.stringify(cacheEntry));
          localStorage.setItem(
            MOST_RECENT_SEARCH_KEY,
            JSON.stringify(searchParams)
          );
        } else {
          console.warn("Failed to cache search results:", cacheError);
        }
      }
    } catch (cacheError) {
      console.warn("Failed to cache search results after cleanup:", cacheError);
    }

    return data;
  } catch (error) {
    console.error("Error searching programs:", error);
    throw error;
  }
}

export async function retrieveProgram(
  programId: string | undefined
): Promise<Event | null> {
  if (!programId) return null;
  try {
    const response = await fetch(`${programGetUrl}/${programId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to retrieve program: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error retrieving program:", error);
    return null;
  }
}
